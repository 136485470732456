<template>
    <div>
        <div class="appraisal-banner"></div>
        <div class="appraisal-data">
        <div>
            <div>
                <span>500,000</span>
                <i>+节</i>
                <p>全平台累计报名学员</p>
            </div>
            <div>
                <span>700</span>
                <i>+位</i>
                <p>颁发鉴定证书人数</p>
            </div>
        </div>
        </div>
        <div class="appraisal-certificate">
            <div class="title">
                <h3>专业从业人员资格证书</h3>
                <i></i>
                <span>PROFESSIONAL CERTIFICATE</span>
            </div>
            <div class="appraisal-certificate-cont">
                <div>
                    <div class="appraisal-first">
                        <div>
                            <figure></figure>
                        </div>
                        <figcaption>
                            <span>中国管理科学研究院保洁资格证书</span>
                            <p>中国管理科学院信息产业研究所颁发专业从业资格证书</p>
                        </figcaption>
                    </div>
                    <div class="appraisal-second">
                        <div>
                            <figure></figure>
                        </div>
                        <figcaption>
                            <span>中国管理科学研究院保洁资格证书</span>
                            <p>中国管理科学院信息产业研究所颁发专业从业资格证书</p>
                        </figcaption>
                    </div>
                </div>
                <div>
                    <div class="appraisal-third">
                        <div>
                            <figure></figure>
                        </div>
                        <figcaption>
                            <span>中国管理科学研究院保洁资格证书</span>
                            <p>中国管理科学院信息产业研究所颁发专业从业资格证书</p>
                        </figcaption>
                    </div>
                    <div class="appraisal-fourth">
                        <div>
                            <figure></figure>
                        </div>
                        <figcaption>
                            <span>中国管理科学研究院保洁资格证书</span>
                            <p>中国管理科学院信息产业研究所颁发专业从业资格证书</p>
                        </figcaption>
                    </div>
                </div>
            </div>
        </div>
        <div class="appraisal-develop">
            <div class="title">
                <h3>行业发展前景</h3>
                <i></i>
                <span>DEVELOPMENT PROSPECTS</span>
            </div>
            <div class="appraisal-develop-cont">
                <div></div>
                <p>近几年，随着我国经济社会快速发展和人民生活质量的不断提高，家政服务业进入快速发展阶段。商务部作为家政服务业主管部门，多次召开并颁布了多项家政扶持政策，为家政行业的发展与规范增添了促进效果。</p>
            </div>
        </div>
        <div class="appraisal-question">
            <div class="title">
                <h3>生活离不开的家政服务有哪些？</h3>
                <i></i>
                <span>HOUSEKEEPING SERVICES</span>
            </div>
            <div class="appraisal-question-cont">
                <div class="question-first">
                    <figure></figure>
                    <figcaption>
                        <h5>新生儿   孕妇</h5>
                        <span>NEWBORN</span>
                        <div>
                            <div>
                                <span>美容养护</span>
                                <span>有机食品</span>
                                <span>催乳</span>
                            </div>
                            <div>
                                <span>母婴用品</span>
                                <span>婴幼早教</span>
                                <span>产后康复</span>
                            </div>
                            <div>
                                <span>育婴照料</span>
                                <span>月嫂</span>
                            </div>
                        </div>
                    </figcaption>
                </div>
                <div class="question-second">
                    <figure></figure>
                    <figcaption>
                        <h5>年轻人  上班族</h5>
                        <span>YOUNG PEOPLE</span>
                        <div>
                            <div>
                                <span>洗涤衣物</span>
                                <span>快递物流</span>
                                <span>保洁做饭</span>
                            </div>
                            <div>
                                <span>小时工</span>
                                <span>收纳整理</span>
                                <span>清洁用品</span>
                            </div>
                            <div>
                                <span>生鲜配送</span>
                            </div>
                        </div>
                    </figcaption>
                </div>
                <div class="question-third">
                    <figure></figure>
                    <figcaption>
                        <h5>中年人</h5>
                        <span>MIDDLE-AGER</span>
                        <div>
                            <div>
                                <span>皮具保养</span>
                                <span>家电维修</span>
                                <span>保姆服务</span>
                            </div>
                            <div>
                                <span>家电清洗</span>
                                <span>新房开荒</span>
                                <span>柴米油盐</span>
                            </div>
                        </div>
                    </figcaption>
                </div>
                <div class="question-fourth">
                    <figure></figure>
                    <figcaption>
                        <h5>老年人</h5>
                        <span>OLD PELPLE</span>
                        <div>
                            <div>
                                <span>护工陪护</span>
                                <span>养老服务</span>
                                <span>社区医疗</span>
                            </div>
                            <div>
                                <span>居家照顾</span>
                                <span>养生保健</span>
                                <span>康复医疗</span>
                            </div>
                        </div>
                    </figcaption>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
    @import '../../style/appraisal.less';
</style>